const dicData = [
    {
      id: 1,
      coordinates: [103.775341, 1.410695],
      latitude: 1.410695,
      longitude: 103.775341,
      color: '#FF0000',
      width: 15,
      severity: 'clean',
      fillLevel: '60%',
      averageRainFall: '0.2mm',
      drainIntakeIndex: '5%'
    },
    {
      id: 2,
      coordinates: [103.776457, 1.410891],
      latitude: 1.410891,
      longitude: 103.776457,
      color: '#00FF00',
      width: 15,
      severity: 'moderate',
      fillLevel: '10%',
      averageRainFall: '0.2mm',
      drainIntakeIndex: '5%'
    },
    {
        id: 3,
        coordinates: [103.782961, 1.410957],
        latitude: 1.410957,
        longitude: 103.782961,
        color: '#00FF00',
        width: 15,
        severity: 'critical',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
    },
    {
        id: 4,
        coordinates: [103.785020, 1.411729],
        latitude: 1.411729,
        longitude: 103.785020,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 5,
        coordinates: [103.788469, 1.412593],
        latitude: 1.412593,
        longitude: 103.788469,
        color: '#00FF00',
        width: 15,
        severity: 'moderate',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 6,
          coordinates: [103.788469, 1.412593],
          latitude: 1.412593,
          longitude: 103.788469,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '10%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 7,
        coordinates: [103.793811, 1.414065],
        latitude: 1.414065,
        longitude: 103.793811,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 8,
        coordinates: [103.797649, 1.415277],
        latitude: 1.415277,
        longitude: 103.797649,
        color: '#00FF00',
        width: 15,
        severity: 'moderate',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 9,
          coordinates:  [103.802836, 1.416185],
          latitude: 1.416185,
          longitude: 103.802836,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '10%',
          averageRainFall: '0.2mm',
          drainIntakeIndex: '5%'
      },
      {
        id: 10,
        coordinates: [103.804342, 1.415319],
        latitude: 1.415319,
        longitude: 103.804342,
        color: '#FF0000',
        width: 15,
        severity: 'clean',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
        id: 11,
        coordinates: [103.805093, 1.414740],
        latitude: 1.414740,
        longitude: 103.805093,
        color: '#00FF00',
        width: 15,
        severity: 'moderate',
        fillLevel: '10%',
        averageRainFall: '0.2mm',
        drainIntakeIndex: '5%'
      },
      {
          id: 12,
          coordinates: [103.805812, 1.414386],
          latitude: 1.414386,
          longitude: 103.805812,
          color: '#00FF00',
          width: 15,
          severity: 'critical',
          fillLevel: '70%',
          averageRainFall: '0.5mm',
          drainIntakeIndex: '15%'
      },

    
  ];


export default dicData