import React, { useEffect } from 'react'
import { ResponsivePie, LegendSymbol } from '@nivo/pie'
import DataServices from '../../Services/DataServices';
import ChartWebSocket from '../../WebSockets/ChartWebSocket';



export const LapsesChart = () => {
  const [response, setResponse] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);
  
  let onWsLapsesMessageReceived = (msg) => {
    setResponse([]);
    setData(msg.litterLapses);
  }
    const setData = (data) => {
      data.map((ac) => {
        setResponse(response => [...response,{id: ac.name,label: ac.name, value: ac.value}]);  
      })
    }
    const retrieveData = () => {
      DataServices.getLapsesCount()
        .then((response) => {
          console.log('response from getLapsesCount: '+JSON.stringify(response.data.data));
          setData(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    useEffect(() => {
      retrieveData();
    }, [])

    useEffect(() => {
      console.log("response", response)
      const uniqueArray = [...new Set(response.map(item => item.id))].map(id => {
          return response.find(item => item.id === id);
      });
      setChartData(uniqueArray)
    }, [response])

    const chartInfo  = chartData.length > 0 && chartData.sort((a, b) => b.value - a.value).slice(0, 5);
    if(chartInfo){
        return (
          <div style={{ height: 90}}>
          <ResponsivePie
          data={chartInfo}
          margin={{right: 0, bottom: 5, left: 0 }} //top: 10, bottom: 20
          padAngle={0.7}
          cornerRadius={3}
          colors={{ scheme: 'brown_blueGreen' }}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
          enableRadialLabels={false}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          radialLabelsLinkOffset={-24}
          radialLabelsSkipAngle={1}
          radialLabelsTextXOffset={0}
          radialLabelsLinkHorizontalLength={10}
          radialLabelsTextColor="white"
          radialLabelsLinkColor={{ from: 'color' }}
          enableSliceLabels={false}
          sliceLabelsSkipAngle={10}
          sliceLabelsTextColor="black"
          innerRadius={0.7}
          theme={{
            textColor: 'white',
            tooltip: {
                container: {
                  color: 'black',
                  background: 'white',
                },
              },
          }}
          legends={[
            {
                anchor: 'bottom-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemWidth: 10,
                itemHeight: 5,
                itemsSpacing: 10,
                symbolSize: 8,
                itemDirection: 'left-to-right',
                symbolSize: 12,
                //symbolShape: 'circle',
                //legendsLimit: 5,
                /* data: chartData.sort((a, b) => b.value - a.value).slice(0, 5),
                //symbolBorderColor: 'transparent',
                symbolShape: (props) => {
                  console.log("dbhdhd", props)
                  chartData && chartData.map((x, i)=>{
                    if(x.id == props.id){
                      return(
                      <LegendSymbol  x={props.x} y={props.y} size={props.size} fill={colorData[i]}
                        borderWidth={props.borderWidth} borderColor={props.borderColor} /> 
                        <circle
                            key={props.id}
                            style={{ fill: colorData[i] }}
                          />
                      )
                    }
                  })
                }  */  
            },  
            
          ]}
      />
      
          <ChartWebSocket onChange={onWsLapsesMessageReceived} />
          </div>
        )
    }
    else{
      return null
    }
   
}
