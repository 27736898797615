import React, { useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';
import authHeader from "../Services/AuthHeader";
import { useLocation } from 'react-router-dom';
//const {REACT_APP_API_URL} = process.env;
const SOCKET_URL = 'https://api.pixvisonz.com/v1/geo-websocket'
//const SOCKET_URL = 'http://localhost:8080/geo-websocket';
//const SOCKET_URL = `${REACT_APP_API_URL}/geo-websocket`;


const ChartWebSocket = (props) => {
  const [message, setMessage] = useState('Chart websocket.');
  const location = useLocation();
  let clientRef;

  let onConnected = () => {
    console.log("Connected to chart websocket!!")
  }

  let onMessageReceived = (msg) => {
      props.onChange(msg);
  }

  useEffect(() => {
    console.log(authHeader().Authorization);
    console.log("ss ", location)
  }, [])

  return (
    <div>
      <SockJsClient
        subscribeHeaders={authHeader()}
        url={SOCKET_URL}
        topics={['/litter/chart/data']}
        onConnect={onConnected}
        onMessage={msg => onMessageReceived(msg)}
        debug={false}
        ref={(client) => {
          clientRef = client;
        }}
      />
    </div>
  );
}

export default ChartWebSocket;