import React from 'react';
import CustomIconSvg from '../../Assets/WaterDropIcon.ico'
import CleanIcon from '../../Assets/Clean.svg';
import ModerateIcon from '../../Assets/Moderate.svg';
import CriticalIcon from '../../Assets/Critical.svg';

const CustomIcon = (props) => {
  let sData = props && props.data && props.data.severity;

  return (
    <div>
      {sData  == "critical" &&(
        <img src={CriticalIcon} width={40} height={40}/> 
      )}
      {sData  == "moderate" &&(
        <img src={ModerateIcon} width={40} height={40}/> 
      )}
      {sData  == "clean" &&(
        <img src={CleanIcon} width={40} height={40}/> 
      )}
    </div>
  );
};

export default CustomIcon;
