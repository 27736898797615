import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from 'react-router-dom';
import AuthService from "./Services/auth";

const sessionDetails = async() => {
    let sessionDetails = JSON.parse(localStorage.getItem(`user`))
    if(sessionDetails == null) { 
       this.handleLogin();
    } else {
        console.log('session already exists');
    }
    return JSON.parse(localStorage.getItem(`user`));
};
const handleLogin = async() => {
    let data = {
        "email":"automatenow.co@gmail.com",
        "password":"Manasa01$"
    }
    try {
        await AuthService.login(data).then(resp => {
            console.log('successfully logged in',JSON.stringify(resp));
        }).catch(err => {
            console.log(JSON.stringify(err));
        });
    } catch(error) {
        console.log('error is ',JSON.stringify(error));
    }
}

const PrivateRoute = ({ children }) => {
    const [auth, setAuth] = useState(false);

    const tokenData = sessionDetails();

    return tokenData ? (children ): <Navigate to="/dashboard" />;
}

export default PrivateRoute;